<template>
  <div></div>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  data() {
    return {

    }
  },
  mounted() {
    console.log('Redirect....')
    if(this.to) {
      if(this.to.startsWith('http')) {
          window.open(this.to, '_blank')
        } else {
          this.$router.push(this.getLink(this.to))
        }
    }
    else {
      console.log('Error to redirect')
    }
  }
}
</script>